<template>
  <div class="saleReportYearly">
    <h1>结算月报表</h1>
    <el-form :inline="true">
      <el-form-item label="年份">
        <el-date-picker :clearable="false" v-model="searchParam.year" type="year" style="width: 110px;" />
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="searchParam.custom" filterable style="width: 120px;" clearable>
          <el-option v-for="customItem in show.customList" :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-switch v-model="searchParam.hasCustom" active-text="区分客户" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border show-summary :summary-method="summary" :span-method="handleSpanMethod" height="calc(100vh - 310px)">
      <el-table-column prop="custom" width="120" label="客户" show-overflow-tooltip />
      <el-table-column label="日期" width="180" show-overflow-tooltip>
        <template #default="scope">
          <el-text>{{ scope.row.dateGe + '~' + scope.row.dateLe }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleQuantity" label="销售数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.saleQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="settlementQuantity" label="已结算数" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.settlementQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="saleAmount" label="结算金额" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.saleAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="salePrice" label="平均单价" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.salePrice) }}
        </template>
      </el-table-column>
      <el-table-column prop="payAmount" label="本期收款" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.payAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="accumulateUnpayAmount" label="累计欠款" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.accumulateUnpayAmount) }}
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math';

const data = reactive({
  list: [],
})

const show = reactive({
  selected: [],
  customList: [],
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.year = new Date()
  searchParam.custom = null
  searchParam.hasCustom = false
}
resetSearchParam()

const commitFindList = () => {
  // if (!searchParam.custom) {
  //   ElMessage.error('请选择客户')
  //   return
  // }
  if (!searchParam.year) {
    ElMessage.error('请选择年份')
    return
  }
  data.list = []
  const params = {}
  const firstDay = new Date(searchParam.year.getFullYear(), 0, 25)
  const lastDay = new Date(searchParam.year.getFullYear() + 1, 0, 24)
  let customList
  if (searchParam.hasCustom && !searchParam.custom) {
    customList = show.customList.map(c => c.value)
  } else {
    customList = [searchParam.custom || null]
  }
  for (const customIndex in customList) {
    params.custom = customList[customIndex]
    for (const date = new Date(firstDay); date <= lastDay; date.setMonth(date.getMonth() + 1)) {
      params.dateGe = util.parseTime(date, '{y}-{m}-{d}')
      const dateLe = new Date(date)
      dateLe.setMonth(date.getMonth() + 1)
      dateLe.setDate(date.getDate() - 1)
      params.dateLe = util.parseTime(dateLe, '{y}-{m}-{d}')
      console.log(params.custom, params.dateGe)
      api.get('/backend/statistics/saleReportByCustom', { params: params }).then(res => {
        data.list.push(res)
        data.list.sort((a, b) => {
          if (a.custom != b.custom) {
            return a.name.localeCompare(b.name)
          }
          return new Date(a.dateGe).getTime() - new Date(b.dateGe).getTime()
        })
      })
    }
  }
}

const summary = () => {
  const list = data.list
  let saleQuantity = 0
  let settlementQuantity = 0
  let saleAmount = 0
  let payAmount = 0
  list.forEach(item => {
    saleQuantity = math.plus(saleQuantity, item.saleQuantity)
    settlementQuantity = math.plus(settlementQuantity, item.settlementQuantity)
    saleAmount = math.plus(saleAmount, item.saleAmount)
    payAmount = math.plus(payAmount, item.payAmount)
  })
  return ['合计', '', math.formatNumber(saleQuantity), math.formatNumber(settlementQuantity), math.formatNumber(saleAmount), '', math.formatNumber(payAmount)]
}

const handleSpanMethod = ({ rowIndex, columnIndex }) => {
  const columnIndexs = [0] // 合并
  if (!columnIndexs.includes(columnIndex)) {
    return [1, 1]
  }
  const row = data.list[rowIndex]
  if (rowIndex > 0) {
    const lastrow = data.list[rowIndex - 1]
    if (columnIndex == 0 && row.custom == lastrow.custom) {
      return [0, 0]
    }
  }
  let span
  for (span = 1; rowIndex + span < data.list.length; span++) {
    const nextrow = data.list[rowIndex + span]
    if (columnIndex == 0 && row.custom != nextrow.custom) {
      break
    }
  }
  return [span, 1]
}
</script>

<style lang="less"></style>